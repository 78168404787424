export const formatDate = (date: string | Date | undefined, regionalSettings: string) => {
  if (date === undefined) {
    return '';
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return date.toLocaleDateString(regionalSettings, {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });
};

export const formatMoney = (amount: number | string, currency: string, regionalSettings: string) => {
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }
  return amount.toLocaleString(regionalSettings, { style: 'currency', currency });
};
